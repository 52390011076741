import React from "react";
import { ComponentProps, KontentRichText } from "../../../types";
import { SectionWrapper } from "../../containers/SectionWrapper";
import * as styles from "./SearchBar.module.css";
import {
  handleSearchFormSubmit,
  handleSearchFormError,
} from "../../siteframe/Header/DesktopNav";
import { useForm } from "react-hook-form";
import { SearchInput } from "../../elements/SearchInput/SearchInput";
import { Link } from "../../elements/Link/Link";
import { CustomRichTextElement } from "../../elements/CustomRichTextElement/CustomRichTextElement";

interface Props extends ComponentProps {
  title: string;
  text: KontentRichText;
}

const defaultSearchFormValues = {
  searchText: "",
  searchType: "site",
};

export const SearchBar: React.FC<Props> = ({ title, text, ...props }) => {
  const {
    control,
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({ defaultValues: defaultSearchFormValues });

  return (
    <SectionWrapper {...props}>
      <div className={styles.searchbar}>
        {title && (
          <div>
            <h2>{title}</h2>
          </div>
        )}
        <div className={styles.searchWrapper}>
          {text.value !== "<p><br></p>" && (
            <CustomRichTextElement content={text} />
          )}
          <form
            onSubmit={handleSubmit(
              handleSearchFormSubmit,
              handleSearchFormError
            )}
          >
            <SearchInput
              placeHolder="SEARCH"
              color="green"
              borderColor={"green"}
              inputProps={register("searchText")}
            />
            <div className={styles.advancedSearchLink}>
              <Link to={"/search"}>Advanced Search</Link>
            </div>
          </form>
        </div>
      </div>
    </SectionWrapper>
  );
};
