import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import * as styles from "./DesktopNav.module.css";
import { navigate } from "gatsby";
import { SearchInput } from "../../elements/SearchInput/SearchInput";
import { useForm } from "react-hook-form";
import { simpleSearch } from "../../blocks/CatalogSearch/CatalogSearch";
import { Link } from "../../elements/Link/Link";
import { HeaderNavigation } from "./Header";
import { RichTextElement } from "@kontent-ai/gatsby-components";
import { KontentLink } from "../../../types";
import { formatLinks } from "../../../lib/links";
import { Cta } from "../../elements/Cta/Cta";

interface Props {
  openHours: string;
  today: string;
  backgroundColor: string | undefined;
  background: string | undefined;
  navigation?: HeaderNavigation;
}

interface FormValues {
  searchText: string;
  searchType: string;
}

export const defaultSearchFormValues = {
  searchText: "",
  searchType: "catalog",
};

export const handleSearchFormSubmit = (
  data: FormValues,
  e?: BaseSyntheticEvent<object, any, any> | undefined
): void => {
  if (data.searchType === "catalog" || !data.searchType) {
    simpleSearch(data);
  } else {
    if (window && window.location.pathname.includes("/search")) {
      window.location.assign(`/search?q=${encodeURI(data.searchText)}`);
    } else {
      navigate(`/search?q=${encodeURI(data.searchText)}`, {
        state: { data },
      });
    }
  }
};

export const handleSearchFormError = (
  errors: {},
  e?: BaseSyntheticEvent<object, any, any> | undefined
): void => {
  console.log("Error", errors, e);
};

export const DesktopNav: React.FC<Props> = ({
  openHours,
  today,
  backgroundColor,
  background,
  navigation,
}) => {
  const [activeNavPanel, setActiveNavPanel] = useState<undefined | string>();
  const [subMenuAnimation, setSubMenuAnimation] = useState("");
  const {
    control,
    register,
    formState: { errors },
    getValues,
    handleSubmit,
  } = useForm({ defaultValues: defaultSearchFormValues });

  const handlePrimaryNavClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const panelLabel = event.currentTarget.dataset?.label;
    if (activeNavPanel === panelLabel) {
      setActiveNavPanel(undefined);
      setSubMenuAnimation("");
    } else {
      setActiveNavPanel(panelLabel);
      setTimeout(() => {
        setSubMenuAnimation(styles.visible);
      });
    }
  };

  useEffect(() => {
    function handleWindowClick(event: MouseEvent) {
      if (
        !event.target?.dataset?.label &&
        activeNavPanel &&
        !event.target?.closest(".js-nav-submenu")
      ) {
        setActiveNavPanel(undefined);
        setSubMenuAnimation("");
      }
    }
    window.addEventListener("click", handleWindowClick);

    return function cleanup() {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [activeNavPanel]);

  return (
    <div className={styles.navWrapper}>
      <nav className={styles.nav}>
        <ul className={styles.mainNavList}>
          {navigation?.map((link, i) => (
            <li key={i}>
              <button
                type="button"
                onClick={handlePrimaryNavClick}
                data-label={link.label}
              >
                {link.label}
              </button>
              <div
                className={`${
                  styles.subMenu
                } ${subMenuAnimation} js-nav-submenu ${
                  link.label === activeNavPanel ? styles.subMenuActive : ""
                }`}
                style={{
                  background: background,
                }}
              >
                {link.links.map((sublinkSection, subLinkSectionIdx) => {
                  return (
                    <div
                      className={styles.subMenuColumn}
                      key={subLinkSectionIdx}
                    >
                      <h3>
                        {sublinkSection.to ? (
                          <Link
                            to={sublinkSection.to}
                            target={sublinkSection.target || "_self"}
                          >
                            {sublinkSection.label}
                          </Link>
                        ) : (
                          <span>{sublinkSection.label}</span>
                        )}
                      </h3>
                      {sublinkSection.links &&
                        sublinkSection.links?.value !== "<p><br></p>" && (
                          <ul>
                            <RichTextElement
                              value={sublinkSection.links.value}
                              linkedItems={sublinkSection.links.modular_content}
                              resolveLinkedItem={(item: KontentLink) => {
                                const link = formatLinks([
                                  item as KontentLink,
                                ])[0];

                                return (
                                  <li>
                                    <Link
                                      to={link.href}
                                      target={link.target || "_self"}
                                    >
                                      {link.label}
                                    </Link>
                                  </li>
                                );
                              }}
                            />
                          </ul>
                        )}
                    </div>
                  );
                })}
              </div>
            </li>
          ))}
          <li>
            <button
              type="button"
              onClick={handlePrimaryNavClick}
              data-label="SEARCH"
            >
              SEARCH
            </button>
            <div
              className={`${styles.subMenu} ${
                styles.searchSubMenu
              } ${subMenuAnimation} js-nav-submenu ${
                "SEARCH" === activeNavPanel ? styles.subMenuActive : ""
              }`}
              style={{ background: background }}
            >
              <div className={styles.subMenuColumn}>
                <form
                  className={styles.desktopSearchForm}
                  onSubmit={handleSubmit(
                    handleSearchFormSubmit,
                    handleSearchFormError
                  )}
                >
                  <SearchInput
                    placeHolder="SEARCH"
                    color="white"
                    borderColor={backgroundColor ? "white" : "green"}
                    inputProps={register("searchText")}
                  />
                  <div className={styles.advancedSearchLink}>
                    <Cta
                      button={true}
                      onClick={() => {
                        const { searchType, searchText } = getValues();
                        const catalogQueryString = searchText
                          ? `&query=any,contains,${searchText}`
                          : "";
                        const siteQueryString = searchText
                          ? `/?q=${searchText}`
                          : "";
                        searchType === "catalog"
                          ? (window.location.href = `https://catalog.lindahall.org/discovery/search?vid=01LINDAHALL_INST:LHL${catalogQueryString}&mode=advanced`)
                          : (window.location.href =
                              "/search" + siteQueryString);
                      }}
                      label="Advanced Search"
                      invert={true}
                    />
                  </div>
                  <div className={styles.searchOptions}>
                    <div className={styles.searchOption}>
                      <input
                        type="radio"
                        id="searchCatalog"
                        value="catalog"
                        {...register("searchType")}
                      />
                      <label htmlFor="searchCatalog">Search the catalog</label>
                    </div>
                    <div className={styles.searchOption}>
                      <input
                        type="radio"
                        id="searchSite"
                        value="site"
                        {...register("searchType")}
                      />
                      <label htmlFor="searchSite">Search the site</label>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </li>
        </ul>
      </nav>
      <div className={styles.date}>
        <p>{today}</p>
        <p>{openHours}</p>
      </div>
    </div>
  );
};
