// extracted by mini-css-extract-plugin
export var advancedSearchLink = "DesktopNav-module--advancedSearchLink--190e9";
export var date = "DesktopNav-module--date--67b34";
export var desktopSearchForm = "DesktopNav-module--desktopSearchForm--60d0e";
export var mainNavList = "DesktopNav-module--mainNavList--4947e";
export var nav = "DesktopNav-module--nav--da25c";
export var navWrapper = "DesktopNav-module--navWrapper--ca163";
export var searchOption = "DesktopNav-module--searchOption--6b510";
export var searchOptions = "DesktopNav-module--searchOptions--a043a";
export var searchSubMenu = "DesktopNav-module--searchSubMenu--db01c";
export var subMenu = "DesktopNav-module--subMenu--f837f";
export var subMenuActive = "DesktopNav-module--subMenuActive--c80cb";
export var subMenuColumn = "DesktopNav-module--subMenuColumn--77e4c";
export var visible = "DesktopNav-module--visible--e0c0f";