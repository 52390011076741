import React from "react";
import { graphql } from "gatsby";
import { SearchBar } from "./SearchBar";
import {
  KontentComponentSettings,
  KontentRichText,
  StringValue,
} from "../../../types";
import { getMultiChoice } from "../../../lib/multichoice";

interface Props extends KontentComponentSettings {
  title: StringValue;
  text: KontentRichText;
}

const SearchBarContainer: React.FC<Props> = ({
  title,
  text,
  component_settings__anchor_name,
  component_settings__hide_on_mobile,
  id,
}) => {
  return (
    <SearchBar
      title={title.value}
      text={text}
      id={id}
      anchor={component_settings__anchor_name.value}
      hideOnMobile={
        getMultiChoice(component_settings__hide_on_mobile) === "yes"
      }
    ></SearchBar>
  );
};

export default SearchBarContainer;

export const fragmentSearchBarContainer = graphql`
  fragment SearchBar on kontent_item_component___search_bar {
    elements {
      title {
        value
      }
      text {
        ...kontentSimpleRichText
      }
      component_settings__anchor_name {
        value
      }
      component_settings__hide_on_mobile {
        value {
          codename
        }
      }
    }
  }
`;
